import { parseAsArrayOf, parseAsStringEnum, useQueryState } from 'nuqs';
import { CreatorHasCampaignStatus } from '@social-garden/api/gql/graphql.ts';

export default function useCreatorHasCampaignStatusQueryState() {
  return useQueryState(
    'creator-has-campaign-status',
    parseAsArrayOf(
      parseAsStringEnum<CreatorHasCampaignStatus>(
        Object.values(CreatorHasCampaignStatus),
      ),
    ).withDefault([]),
  );
}
