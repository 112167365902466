import { graphql } from '../gql';

export const managerDocument = graphql(`
  query managerDocument {
    whoAmI {
      ... on ManagerAuthenticatable {
        ...managerAuthenticatable
        user {
          ...manager
        }
      }
    }
  }
`);

export const managersDocument = graphql(`
  query managers($first: Int!, $page: Int) {
    managers(first: $first, page: $page) {
      data {
        ...manager
      }
      paginatorInfo {
        total
      }
    }
  }
`);

export const deleteManagerDocument = graphql(`
  mutation deleteManager {
    deleteManager
  }
`);

export const logoutManagerDocument = graphql(`
  mutation logoutManager {
    logoutManager
  }
`);

export const impersonateManagerDocument = graphql(`
  mutation impersonateManager($id: UUID!) {
    impersonateManager(id: $id)
  }
`);

export const forgotManagerPasswordDocument = graphql(`
  mutation forgotManagerPassword($input: ForgotManagerPasswordInput!) {
    forgotManagerPassword(input: $input)
  }
`);

export const loginManagerDocument = graphql(`
  mutation loginManager($input: LoginManagerInput!) {
    loginManager(input: $input)
  }
`);

export const updateManagerPasswordDocument = graphql(`
  mutation updateManagerPassword($input: UpdateManagerPasswordInput!) {
    updateManagerPassword(input: $input)
  }
`);

export const requestVerificationOfNewManagerEmailDocument = graphql(`
  mutation requestVerificationOfNewManagerEmail(
    $input: RequestVerificationOfNewManagerEmailInput!
  ) {
    requestVerificationOfNewManagerEmail(input: $input)
  }
`);

export const recoverManagerPasswordDocument = graphql(`
  mutation recoverManagerPassword($input: RecoverManagerPasswordInput!) {
    recoverManagerPassword(input: $input)
  }
`);

export const updateManagerLocaleDocument = graphql(`
  mutation updateManagerLocale($input: UpdateManagerLocaleInput!) {
    updateManagerLocale(input: $input) {
      id
      locale
    }
  }
`);

export const verifyManagerEmailDocument = graphql(`
  mutation verifyManagerEmail($input: VerifyManagerEmailInput!) {
    verifyManagerEmail(input: $input)
  }
`);

export const resendManagerVerificationEmailDocument = graphql(`
  mutation resendManagerVerificationEmail(
    $input: ResendManagerVerificationEmailInput!
  ) {
    resendManagerVerificationEmail(input: $input)
  }
`);

export const verifyAndUpdateManagerEmailDocument = graphql(`
  mutation verifyAndUpdateManagerEmail($input: VerifyManagerEmailInput!) {
    verifyAndUpdateManagerEmail(input: $input) {
      ...manager
    }
  }
`);
