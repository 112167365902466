import { Outlet, useLocation, useNavigate } from 'react-router';
import { useEffect } from 'react';
import useManagerLayoutOutletContext from '../hooks/useManagerLayoutOutletContext.ts';

export default function SetupLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const { company, brands } = useManagerLayoutOutletContext();

  useEffect(() => {
    if (company.currentSubscription?.status === 'past_due') {
      if (location.pathname !== '/subscription-past-due') {
        navigate('/subscription-past-due');
      }
    } else if (
      !company.currentSubscription ||
      !company.currentSubscription.subscriptionPlan ||
      company.currentSubscription.status !== 'active'
    ) {
      if (location.pathname !== '/choose-subscription') {
        navigate('/choose-subscription');
      }
    } else if (brands.length === 0) {
      if (location.pathname !== '/create-first-brand') {
        navigate('/create-first-brand');
      }
    } else {
      navigate('/');
    }
  }, [location.pathname, brands.length, company.currentSubscription, navigate]);

  return <Outlet />;
}
