import { CreatorHasCampaignStatus } from '@social-garden/api/gql/graphql.ts';
import { Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuarterStroke } from '@fortawesome/pro-regular-svg-icons/faCircleQuarterStroke';
import { faCircleHalfStroke } from '@fortawesome/pro-regular-svg-icons/faCircleHalfStroke';
import { faCircleThreeQuartersStroke } from '@fortawesome/pro-regular-svg-icons/faCircleThreeQuartersStroke';
import { faCircleXmark } from '@fortawesome/pro-regular-svg-icons/faCircleXmark';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle';
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons/faCircleExclamation';
import { BLUE, GREEN, ORANGE, RED } from '@social-garden/utils/colors.ts';
import { assertUnreachable } from '@social-garden/utils/helpers.ts';
import { useTranslation } from 'react-i18next';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

const ICON_SIZE: SizeProp = 'lg';

interface CampaignReportDataStatusProps {
  creatorHasCampaignStatus: CreatorHasCampaignStatus;
}

export default function CampaignReportDataStatus({
  creatorHasCampaignStatus,
}: CampaignReportDataStatusProps) {
  const { t } = useTranslation(['common']);

  switch (creatorHasCampaignStatus) {
    case CreatorHasCampaignStatus.APPLIED:
      return (
        <Tooltip title={t('common:report.dataStatus.fetching')}>
          <FontAwesomeIcon
            icon={faCircleQuarterStroke}
            size={ICON_SIZE}
            color={BLUE['500']}
          />
        </Tooltip>
      );
    case CreatorHasCampaignStatus.PREVIEW_PENDING:
    case CreatorHasCampaignStatus.PREVIEW_SUBMITTED:
      return (
        <Tooltip title={t('common:report.dataStatus.fetching')}>
          <FontAwesomeIcon
            icon={faCircleHalfStroke}
            size={ICON_SIZE}
            color={BLUE['500']}
          />
        </Tooltip>
      );
    case CreatorHasCampaignStatus.CONTENT_PENDING:
    case CreatorHasCampaignStatus.CONTENT_SUBMITTED:
      return (
        <Tooltip title={t('common:report.dataStatus.fetching')}>
          <FontAwesomeIcon
            icon={faCircleThreeQuartersStroke}
            size={ICON_SIZE}
            color={BLUE['500']}
          />
        </Tooltip>
      );
    case CreatorHasCampaignStatus.REPORT_COMPLETED:
      return (
        <Tooltip title={t('common:report.dataStatus.completed')}>
          <FontAwesomeIcon
            icon={faCheckCircle}
            size={ICON_SIZE}
            color={GREEN['500']}
          />
        </Tooltip>
      );
    case CreatorHasCampaignStatus.REPORT_FAILED:
      return (
        <Tooltip title={t('common:report.dataStatus.failed')}>
          <FontAwesomeIcon
            icon={faCircleExclamation}
            size={ICON_SIZE}
            color={RED['500']}
          />
        </Tooltip>
      );
    case CreatorHasCampaignStatus.APPLICATION_DECLINED:
    case CreatorHasCampaignStatus.DISCONTINUED:
    case CreatorHasCampaignStatus.CAMPAIGN_SUSPENDED:
      return (
        <Tooltip title={t('common:report.dataStatus.failed')}>
          <FontAwesomeIcon
            icon={faCircleXmark}
            size={ICON_SIZE}
            color={ORANGE['500']}
          />
        </Tooltip>
      );
    default:
      assertUnreachable(creatorHasCampaignStatus);
  }
}
