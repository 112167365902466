import { Outlet, useLocation } from 'react-router';
import { useSuspenseQuery } from '@apollo/client';
import { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack, Toolbar } from '@mui/material';
import { myBrandsDocument } from '@social-garden/api/documents/brand.ts';
import { myCompanyDocument } from '@social-garden/api/documents/company.ts';
import { managerDocument } from '@social-garden/api/documents/manager.ts';
import SuspenseFallback from '../components/SuspenseFallback.tsx';
import { removeToken } from '../utils/authToken.ts';
import AppBar from '../components/AppBar.tsx';
import Drawer from '../components/Drawer.tsx';
import { ManagerLayoutOutletContext } from '../hooks/useManagerLayoutOutletContext.ts';

const toolbarVariant: 'regular' | 'dense' = 'dense';

export default function ManagerLayout() {
  const {
    i18n: { changeLanguage },
  } = useTranslation();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const {
    data: { whoAmI },
  } = useSuspenseQuery(managerDocument);
  const {
    data: { myCompany },
  } = useSuspenseQuery(myCompanyDocument);
  const {
    data: { myBrands },
  } = useSuspenseQuery(myBrandsDocument);

  const handleOpenDrawer = useCallback(() => {
    setMobileOpen(true);
  }, []);

  const handleCloseDrawer = useCallback(() => {
    setMobileOpen(false);
  }, []);

  const isSetupCompleted = useMemo<boolean>(() => {
    return Boolean(
      myCompany.currentSubscription &&
        myCompany.currentSubscription.subscriptionPlan &&
        myCompany.currentSubscription.status === 'active' &&
        myBrands.length > 0,
    );
  }, [myBrands, myCompany.currentSubscription]);

  const drawerWidth = useMemo(() => {
    if (isSetupCompleted) {
      return 250;
    }
    return 0;
  }, [isSetupCompleted]);

  useEffect(() => {
    (async () => {
      if (whoAmI.__typename === 'ManagerAuthenticatable') {
        await changeLanguage(whoAmI.locale);
      } else {
        removeToken();
      }
    })();
  }, [whoAmI, changeLanguage]);

  if (whoAmI.__typename !== 'ManagerAuthenticatable') {
    throw new Error('Authenticated user is not a manager!');
  }

  return (
    <Stack flex={1} direction="row">
      <AppBar
        drawerWidth={drawerWidth}
        toolbarVariant={toolbarVariant}
        isSetupCompleted={isSetupCompleted}
        manager={whoAmI.user}
        company={myCompany}
        onOpenDrawerClick={handleOpenDrawer}
      />
      {isSetupCompleted ? (
        <Drawer
          drawerWidth={drawerWidth}
          toolbarVariant={toolbarVariant}
          mobileOpen={mobileOpen}
          onCloseDrawerClick={handleCloseDrawer}
        />
      ) : null}
      <Stack
        flex={1}
        width={{
          md: `calc(100% - ${drawerWidth}px)`,
        }}
        py={{ xs: 2, sm: 3 }}
        component="main">
        <Toolbar variant={toolbarVariant} />
        <Suspense key={location.key} fallback={<SuspenseFallback />}>
          <Outlet
            context={
              {
                company: myCompany,
                brands: myBrands,
              } as ManagerLayoutOutletContext
            }
          />
        </Suspense>
      </Stack>
    </Stack>
  );
}
