import { MenuItem, Select, SelectProps } from '@mui/material';
import { useSuspenseQuery } from '@apollo/client';
import { myBrandsDocument } from '@social-garden/api/documents/brand.ts';
import { getUriWithHash } from '@social-garden/utils/helpers.ts';

export default function BrandSelect<Value>(
  props: Omit<SelectProps<Value>, 'children'>,
) {
  const {
    data: { myBrands },
  } = useSuspenseQuery(myBrandsDocument);

  return (
    <Select<Value> {...props}>
      {myBrands.map((brand) => (
        <MenuItem key={brand.id} value={brand.id}>
          <div style={{ display: 'inline-block' }}>
            <img
              style={{
                display: 'inline-block',
                width: 20,
                verticalAlign: 'text-bottom',
                marginRight: 8,
                aspectRatio: 1,
                borderRadius: '50%',
              }}
              src={getUriWithHash(brand.logo)}
              loading="lazy"
              alt={brand.name}
            />
            <span>{brand.name}</span>
          </div>
        </MenuItem>
      ))}
    </Select>
  );
}
