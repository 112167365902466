import { useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { creatorsOfCampaignDocument } from '@social-garden/api/documents/creatorHasCampaign.ts';
import { json2csv } from 'json-2-csv';
import {
  CreatorHasCampaignStatus,
  CreatorsOfCampaignQuery,
} from '@social-garden/api/gql/graphql.ts';
import {
  assertUnreachable,
  formatPercentage,
} from '@social-garden/utils/helpers.ts';
import { KeysList } from 'json-2-csv/lib/types';

const KEYS_LIST: KeysList = [
  {
    field: 'id',
    title: 'ID',
  },
  {
    field: 'username',
    title: 'Username',
  },
  {
    field: 'status',
    title: 'Status',
  },
  {
    field: 'rewardValue',
    title: 'Remuneration',
  },
  {
    field: 'channelName',
    title: 'Channel username',
  },
  {
    field: 'channelUrl',
    title: 'Channel URL',
  },
  {
    field: 'followersCount',
    title: 'Followers',
  },
  {
    field: 'averageEngagementRate',
    title: 'Average engagement rate',
  },
  {
    field: 'averageReach',
    title: 'Average reach',
  },
  {
    field: 'mediaCount',
    title: 'Media count',
  },
] as const;

function mapCreatorsOfCampaignToExportData({
  id,
  status,
  rewardValue,
  creator,
  channel,
}: CreatorsOfCampaignQuery['creatorsOfCampaign']['data'][0]) {
  let channelName: string | undefined;
  let followersCount: number | undefined;
  let mediaCount: number | undefined;
  if (channel) {
    switch (channel.channelable.__typename) {
      case 'InstagramChannel':
        channelName = channel.channelable.username;
        followersCount = channel.channelable.followersCount;
        mediaCount = channel.channelable.mediaCount;
        break;
      case 'TiktokChannel':
        channelName = channel.channelable.displayName;
        followersCount = channel.channelable.followerCount;
        mediaCount = channel.channelable.videoCount;
        break;
      case 'YoutubeChannel':
        channelName = channel.channelable.title;
        followersCount = channel.channelable.subscriberCount;
        mediaCount = channel.channelable.videoCount;
        break;
      default:
        assertUnreachable(channel.channelable);
    }
  }
  return {
    id: id,
    username: creator.username,
    status: status,
    rewardValue: rewardValue,
    channelName: channelName,
    channelUrl: channel?.channelable.url,
    followersCount: followersCount,
    averageEngagementRate: formatPercentage(
      channel?.channelable.averageEngagementRate || 0,
      'en',
    ),
    averageReach: channel?.channelable.averageReach,
    mediaCount: mediaCount,
  };
}

type ExportCreatorsOfCampaignHandler = (props: {
  campaignId: string;
  campaignName: string;
  creatorHasCampaignStatus: CreatorHasCampaignStatus[];
}) => Promise<void>;

export default function useExportCreatorsOfCampaign() {
  const [creatorsOfCampaign] = useLazyQuery(creatorsOfCampaignDocument, {
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  });

  const handleExport = useCallback<ExportCreatorsOfCampaignHandler>(
    async (props) => {
      const { data } = await creatorsOfCampaign({
        variables: {
          input: {
            campaignId: props.campaignId,
            status: props.creatorHasCampaignStatus,
          },
          page: 1,
          first: 100,
        },
      });
      if (data === undefined) {
        return;
      }
      const convertedData = data.creatorsOfCampaign.data.map(
        mapCreatorsOfCampaignToExportData,
      );
      const csvString = json2csv(convertedData, {
        keys: KEYS_LIST,
      });
      const blob = new Blob([csvString], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `CreatorList_${props.campaignName}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    [creatorsOfCampaign],
  );

  return [handleExport] as const;
}
