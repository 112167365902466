import usePreventNavigationAndUnload from './usePreventNavigationAndUnload.ts';

interface PreventLeavingUnsavedFormParams<T> {
  dirtyFields: Partial<Readonly<T>>;
  isSubmitting: boolean;
}

export default function usePreventLeavingUnsavedForm<T>({
  dirtyFields,
  isSubmitting,
}: PreventLeavingUnsavedFormParams<T>) {
  usePreventNavigationAndUnload(
    Object.keys(dirtyFields).length > 0 && !isSubmitting,
  );
}
