import { assertUnreachable } from './helpers';
import { ChannelType, ChannelTypename } from './types';
import { INSTAGRAM, TIKTOK, YOUTUBE } from './colors';

export function getChannelTypeColor(type: ChannelType | ChannelTypename) {
  switch (type) {
    case ChannelType.INSTAGRAM:
    case 'InstagramChannel':
      return INSTAGRAM;
    case ChannelType.TIKTOK:
    case 'TiktokChannel':
      return TIKTOK;
    case ChannelType.YOUTUBE:
    case 'YoutubeChannel':
      return YOUTUBE;
    default:
      assertUnreachable(type);
  }
}

export function getChannelTypeName(type: ChannelType | ChannelTypename) {
  switch (type) {
    case ChannelType.INSTAGRAM:
    case 'InstagramChannel':
      return 'Instagram';
    case ChannelType.TIKTOK:
    case 'TiktokChannel':
      return 'TikTok';
    case ChannelType.YOUTUBE:
    case 'YoutubeChannel':
      return 'YouTube';
    default:
      assertUnreachable(type);
  }
}

export const CHANNEL_TYPENAMES: ChannelTypename[] = [
  'InstagramChannel',
  'TiktokChannel',
  'YoutubeChannel',
];

export const INSTAGRAM_CHANNEL_PERMISSIONS = [
  'public_profile',
  'email',
  'pages_show_list',
  'pages_read_engagement',
  'instagram_basic',
  'instagram_manage_insights',
  'business_management',
];

export const TIKTOK_CHANNEL_SCOPES = [
  'user.info.basic',
  'user.info.profile',
  'user.info.stats',
  'video.list',
];

export const YOUTUBE_CHANNEL_SCOPES = [
  'https://www.googleapis.com/auth/userinfo.email',
  'https://www.googleapis.com/auth/youtube.readonly',
];
