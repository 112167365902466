import { useEffect, useState } from 'react';
import {
  getChannelTypeOEmbedUrl,
  OEmbedResponse,
} from '@social-garden/utils/oEmbed.ts';
import { ChannelType } from '@social-garden/utils/types.ts';
import { z } from 'zod';

const OEmbedResponseSchema = z.union([
  z.object({
    version: z.literal('1.0'),
    title: z.string().optional(),
    type: z.literal('photo'),
    url: z.string(),
    width: z.number(),
    height: z.number(),
  }),
  z.object({
    version: z.literal('1.0'),
    title: z.string().optional(),
    type: z.literal('video'),
    html: z.string(),
    width: z.number(),
    height: z.number(),
  }),
  z.object({
    version: z.literal('1.0'),
    title: z.string().optional(),
    type: z.literal('rich'),
    html: z.string(),
    width: z.number(),
    height: z.number().optional(),
  }),
]);

export default function useChannelTypeOEmbed(
  url: string,
  channelType: ChannelType,
  options: { maxWidth?: number; maxHeight?: number } = {},
) {
  const [data, setData] = useState<OEmbedResponse>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(
          getChannelTypeOEmbedUrl({
            channelType,
            url,
            accessToken: `${import.meta.env.VITE_FACEBOOK_APP_ID}|${
              import.meta.env.VITE_FACEBOOK_CLIENT_TOKEN
            }`,
            maxWidth: options.maxWidth,
            maxHeight: options.maxHeight,
          }),
          {
            mode: 'cors',
          },
        );
        if (!response.ok) {
          setError(`${response.status}: ${response.statusText}`);
          setData(undefined);
          setLoading(false);
          return;
        }
        const result = OEmbedResponseSchema.safeParse(await response.json());
        if (!result.success) {
          setError(result.error.message);
          setData(undefined);
          setLoading(false);
        }
        setError(undefined);
        setData(result.data);
        setLoading(false);
      } catch {
        setError('Something went wrong!');
        setData(undefined);
        setLoading(false);
      }
    })();
  }, [url, options.maxWidth, options.maxHeight, channelType]);

  return {
    data,
    loading,
    error,
  };
}
