import { ApolloClient, ApolloProvider, from } from '@apollo/client';
import { ReactNode, useMemo } from 'react';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { SentryLink } from 'apollo-link-sentry';
import { useSnackbar } from 'notistack';
import { getToken, removeToken } from '../utils/authToken.ts';
import { cache, persistor } from '../utils/apolloInMemoryCache.ts';

const sentryLink = new SentryLink({
  uri: `${import.meta.env.VITE_APP_URL}/graphql`,
  setFingerprint: true,
  setTransaction: true,
});

const authLink = setContext((_, { headers }) => {
  const token = getToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : undefined,
    },
  };
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error) => !!error,
  },
});

const httpLink = createUploadLink({
  uri: `${import.meta.env.VITE_APP_URL}/graphql`,
});

export default function ApolloClientProvider({
  children,
}: {
  children?: ReactNode;
}) {
  const { enqueueSnackbar } = useSnackbar();

  const errorLink = useMemo(
    () =>
      onError(({ graphQLErrors }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message }) => {
            if (message === 'Unauthenticated.') {
              removeToken();
              persistor.purge().then(() => {
                location.reload();
              });
              return;
            }
            enqueueSnackbar(message, {
              variant: 'error',
            });
          });
        }
      }),
    [enqueueSnackbar],
  );

  const link = useMemo(
    () => from([sentryLink, authLink, errorLink, retryLink, httpLink]),
    [errorLink],
  );

  const client = useMemo(
    () =>
      new ApolloClient({
        defaultOptions: {
          watchQuery: {
            fetchPolicy: 'cache-and-network',
          },
        },
        link,
        cache,
      }),
    [link],
  );

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
