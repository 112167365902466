import {
  Card,
  CardContent,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Typography,
  Grid2,
} from '@mui/material';
import { faListCheck } from '@fortawesome/pro-thin-svg-icons/faListCheck';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { CreatorHasCampaignStatus } from '@social-garden/api/gql/graphql.ts';
import { creatorsOfCampaignDocument } from '@social-garden/api/documents/creatorHasCampaign.ts';
import StackList from '../../components/StackList.tsx';
import EmptyState from '../../components/EmptyState.tsx';
import CreatorHasCampaignListItem, {
  CreatorHasCampaignListItemData,
} from '../../components/CreatorHasCampaignListItem.tsx';
import SuspenseFallback from '../../components/SuspenseFallback.tsx';
import CreatorHasCampaignStatusSelect from '../../components/CreatorHasCampaignStatusSelect.tsx';
import useCreatorHasCampaignStatusQueryState from '../../hooks/useCreatorHasCampaignStatusQueryState.ts';

interface CreatorsOfCampaignProps {
  campaignId: string;
}

export default function CreatorsOfCampaign({
  campaignId,
}: CreatorsOfCampaignProps) {
  const { t } = useTranslation(['common', 'manager']);
  const [status, setStatus] = useCreatorHasCampaignStatusQueryState();

  const { data, loading, fetchMore } = useQuery(creatorsOfCampaignDocument, {
    variables: {
      input: {
        campaignId: campaignId,
        status: status,
      },
      first: 25,
      page: 1,
    },
  });

  const renderItem = useCallback(
    (itemData: CreatorHasCampaignListItemData) => (
      <CreatorHasCampaignListItem
        key={itemData.id}
        data={itemData}
        variant="outlined"
      />
    ),
    [],
  );

  const handleOnStatusChange = useCallback(
    async (event: SelectChangeEvent<CreatorHasCampaignStatus[]>) => {
      const selected = event.target.value;
      if (Array.isArray(selected)) {
        await setStatus(selected);
      }
    },
    [setStatus],
  );

  return (
    <Grid2 container spacing={2}>
      <Grid2 container size={12} alignItems="center">
        <Grid2
          size={{
            xs: 12,
            sm: 12,
            md: 'grow',
            lg: 'grow',
          }}>
          <Typography variant="overline" fontSize={20}>
            {t('manager:campaign.creatorList.header')}
          </Typography>
        </Grid2>
        <Grid2
          size={{
            xs: 12,
            sm: 12,
            md: 6,
            lg: 4,
          }}>
          <FormControl fullWidth>
            <InputLabel id="status-filter-label">
              {t('manager:creatorHasCampaign.filter.status.label')}
            </InputLabel>
            <CreatorHasCampaignStatusSelect<CreatorHasCampaignStatus[]>
              variant="outlined"
              labelId="status-filter-label"
              label={t('manager:creatorHasCampaign.filter.status.label')}
              value={status}
              multiple
              onChange={handleOnStatusChange}
            />
          </FormControl>
        </Grid2>
      </Grid2>
      <Grid2 size={12}>
        {data ? (
          <StackList<CreatorHasCampaignListItemData>
            spacing={2}
            loading={loading}
            paginator={data.creatorsOfCampaign}
            fetchMore={fetchMore}
            renderItem={renderItem}
            ListEmptyComponent={
              <Card variant="outlined">
                <CardContent>
                  <EmptyState
                    icon={faListCheck}
                    text={t('manager:campaign.creatorList.empty')}
                  />
                </CardContent>
              </Card>
            }
          />
        ) : (
          <SuspenseFallback minHeight={300} />
        )}
      </Grid2>
    </Grid2>
  );
}
