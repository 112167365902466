import { assertUnreachable } from '@social-garden/utils/helpers.ts';
import { useMemo } from 'react';
import {
  Card,
  CardContent,
  CardProps,
  Divider,
  Typography,
} from '@mui/material';
import PreviewRevisionVideo from './PreviewRevisionVideo.tsx';
import PreviewRevisionImage from './PreviewRevisionImage.tsx';
import ReplaceUrlWithLink from './ReplaceUrlWithLink.tsx';

interface PreviewRevisionCardProps extends CardProps {
  previewRevision: {
    id: string;
    caption: string;
  };
  mediaType: 'image' | 'video';
}

export default function PreviewRevisionCard({
  previewRevision,
  mediaType,
  ...rest
}: PreviewRevisionCardProps) {
  const previewContent = useMemo(() => {
    switch (mediaType) {
      case 'image':
        return <PreviewRevisionImage previewRevisionId={previewRevision.id} />;
      case 'video':
        return <PreviewRevisionVideo previewRevisionId={previewRevision.id} />;
      default:
        assertUnreachable(mediaType);
    }
  }, [mediaType, previewRevision.id]);

  return (
    <Card {...rest}>
      {previewContent}
      <Divider />
      <CardContent>
        <Typography
          style={{
            whiteSpace: 'pre-line',
          }}
          variant="body2">
          <ReplaceUrlWithLink>{previewRevision.caption}</ReplaceUrlWithLink>
        </Typography>
      </CardContent>
    </Card>
  );
}
