import { OEmbedResponse } from '@social-garden/utils/oEmbed.ts';
import { assertUnreachable } from '@social-garden/utils/helpers.ts';

interface OEmbedProps {
  data: OEmbedResponse;
  defaultHeight: number;
  maxWidth?: number;
  maxHeight?: number;
}

export default function OEmbed({
  data,
  defaultHeight,
  maxWidth,
  maxHeight,
}: OEmbedProps) {
  switch (data.type) {
    case 'photo':
      return (
        <img
          style={{
            display: 'block',
            width: '100%',
            height: 'auto',
            aspectRatio: data.width / data.height,
            maxWidth,
            maxHeight,
          }}
          src={data.url}
          alt={data.title}
        />
      );
    case 'video':
    case 'rich':
      return (
        <iframe
          style={{
            display: 'block',
            width: '100%',
            height: data.height || defaultHeight,
            maxWidth,
            maxHeight,
            border: 0,
            margin: 0,
            padding: 0,
            outline: 0,
            boxShadow: 'none',
          }}
          srcDoc={`${data.html}<style>html,body{margin: 0; padding: 0; border: 0; outline: 0}</style>`}
          title={data.title}
        />
      );
    default:
      assertUnreachable(data);
  }
}
