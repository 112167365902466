import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons/faTrashCan';
import { ChangeEvent, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import {
  DeleteManagerFieldValues,
  DeleteManagerSchema,
} from '../../constants/ValidationSchema.ts';
import useDeleteManager from '../../hooks/useDeleteManager.ts';
import Section from '../../components/Section.tsx';

export default function DeleteAccount() {
  const { t } = useTranslation(['manager']);
  const [open, setOpen] = useState<boolean>(false);
  const [deleteManager, { loading, error }] = useDeleteManager();

  const {
    setValue,
    reset,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<DeleteManagerFieldValues>({
    resolver: zodResolver(DeleteManagerSchema),
    mode: 'all',
    defaultValues: {
      isConfirmed: false,
    },
  });

  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    reset();
  }, [reset]);

  const handleOnInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue(
        'isConfirmed',
        event.target.value === t('manager:deleteAccount.confirm.word'),
        {
          shouldValidate: true,
          shouldTouch: true,
          shouldDirty: true,
        },
      );
    },
    [t, setValue],
  );

  const handleOnDeleteAccount = useCallback(async () => {
    setOpen(false);
    reset();
    await deleteManager();
  }, [reset, deleteManager]);

  return (
    <Section header={t('manager:deleteAccount.header')}>
      <Alert severity="error">{t('manager:deleteAccount.primaryText')}</Alert>
      {error ? (
        <Alert severity="info">
          {t('manager:deleteAccount.secondaryText')}
        </Alert>
      ) : null}
      <Button
        variant="outlined"
        color="error"
        startIcon={<FontAwesomeIcon icon={faTrashCan} />}
        onClick={handleClickOpen}>
        {t('manager:deleteAccount.button')}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <form noValidate onSubmit={handleSubmit(handleOnDeleteAccount)}>
          <DialogTitle color="error">
            {t('manager:deleteAccount.dialog.title')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('manager:deleteAccount.dialog.text', {
                confirmWord: t('deleteAccount.confirm.word'),
              })}
            </DialogContentText>
            <TextField
              fullWidth
              variant="standard"
              margin="dense"
              error={!!errors.isConfirmed}
              label={t('manager:deleteAccount.confirm.label')}
              onChange={handleOnInputChange}
            />
          </DialogContent>
          <DialogActions>
            <Button color="inherit" type="reset" onClick={handleClose}>
              {t('manager:deleteAccount.dialog.actionButton.cancel')}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="error"
              disabled={!isValid || loading}>
              {t('manager:deleteAccount.dialog.actionButton.confirm')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Section>
  );
}
