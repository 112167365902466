import { Button, Container, Typography, Grid2 } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router';
import { useCallback, MouseEventHandler } from 'react';
import { useSuspenseQuery } from '@apollo/client';
import { myBrandsDocument } from '@social-garden/api/documents/brand.ts';
import BrandLogo from '../../components/BrandLogo.tsx';
import useBrandUsage from '../../hooks/useBrandUsage.ts';
import PageTitle from '../../components/PageTitle.tsx';

export default function Brands() {
  const { t } = useTranslation(['common', 'manager']);
  const {
    data: { myBrands },
  } = useSuspenseQuery(myBrandsDocument);
  const navigate = useNavigate();
  const { exceeded } = useBrandUsage();

  const handleOnBrandEditClick = useCallback<
    MouseEventHandler<HTMLButtonElement>
  >(
    (event) => {
      navigate(`/update-brand/${event.currentTarget.value}`);
    },
    [navigate],
  );

  return (
    <Container>
      <Grid2 container spacing={4}>
        <Grid2 container size={12} alignItems="center">
          <Grid2
            size={{
              xs: 12,
              md: 'grow',
            }}>
            <PageTitle>{t('manager:navigation.brands')}</PageTitle>
          </Grid2>
          <Grid2
            size={{
              xs: 12,
              md: 'auto',
            }}>
            <Button
              variant="contained"
              size="large"
              disabled={exceeded}
              component={Link}
              to="/create-brand">
              {t('manager:brand.create.newButton')}
            </Button>
          </Grid2>
        </Grid2>
        {myBrands.map((brand) => (
          <Grid2
            key={brand.id}
            container
            size={{
              xs: 12,
              sm: 6,
              md: 4,
              lg: 3,
            }}
            spacing={1}
            textAlign="center">
            <Grid2 size={12}>
              <BrandLogo
                sx={{
                  width: 'auto',
                  height: 'auto',
                  aspectRatio: 1,
                }}
                src={brand.logo}
                alt={brand.name}
              />
            </Grid2>
            <Grid2 size={12}>
              <Typography
                sx={{
                  overflowWrap: 'break-word',
                  wordBreak: 'break-word',
                }}
                variant="h5">
                {brand.name}
              </Typography>
            </Grid2>
            <Grid2 size={12}>
              <Button value={brand.id} onClick={handleOnBrandEditClick}>
                {t('common:edit')}
              </Button>
            </Grid2>
          </Grid2>
        ))}
      </Grid2>
    </Container>
  );
}
