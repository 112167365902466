import { DetailedHTMLProps, ImgHTMLAttributes } from 'react';
import { useQuery } from '@apollo/client';
import { previewFileDocument } from '@social-garden/api/documents/creatorHasCampaign.ts';
import SuspenseFallback from './SuspenseFallback.tsx';

interface PreviewRevisionImageProps
  extends Omit<
    DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
    'src'
  > {
  previewRevisionId: string;
}

export default function PreviewRevisionImage({
  style,
  previewRevisionId,
  alt = 'Preview',
  ...rest
}: PreviewRevisionImageProps) {
  const { data, loading, error } = useQuery(previewFileDocument, {
    variables: {
      id: previewRevisionId,
    },
  });

  if (loading && data === undefined) {
    return <SuspenseFallback />;
  }

  if (error || data === undefined) {
    return null;
  }

  return (
    <img
      style={{
        display: 'block',
        width: '100%',
        minHeight: 350,
        maxHeight: '80vh',
        objectFit: 'contain',
        ...style,
      }}
      src={data.previewFile.uri}
      alt={alt}
      {...rest}
    />
  );
}
