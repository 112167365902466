import { IconButton, InputAdornment, TextField } from '@mui/material';
import { FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapLocationDot } from '@fortawesome/pro-regular-svg-icons/faMapLocationDot';
import { useCallback } from 'react';
import { getGoogleMapsSearchUrl } from '@social-garden/utils/helpers.ts';
import { FormFieldProps } from '../utils/types.ts';

export default function AddressField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  field: { name, value, onChange, onBlur },
  fieldState: { error, invalid },
  formState: { isSubmitting },
}: FormFieldProps<TFieldValues, TName>) {
  const { t } = useTranslation(['common']);

  const handleOnOpenLocation = useCallback(() => {
    if (value) {
      window.open(getGoogleMapsSearchUrl(value), '_blank');
    }
  }, [value]);

  return (
    <TextField
      fullWidth
      label={t('common:campaign.address')}
      name={name}
      defaultValue={value}
      disabled={isSubmitting}
      error={invalid}
      helperText={error?.message}
      slotProps={{
        input: {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton disabled={!value} onClick={handleOnOpenLocation}>
                <FontAwesomeIcon
                  icon={faMapLocationDot}
                  width={24}
                  height={24}
                />
              </IconButton>
            </InputAdornment>
          ),
        },
      }}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
}
