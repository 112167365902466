import { Toolbar, AppBar as MuiAppBar } from '@mui/material';
import { MouseEventHandler } from 'react';
import TopNavigation from './TopNavigation.tsx';

interface AppBarProps {
  drawerWidth: number;
  toolbarVariant: 'regular' | 'dense';
  isSetupCompleted: boolean;
  manager: {
    email: string;
  };
  company: {
    name: string;
  };
  onOpenDrawerClick: MouseEventHandler<HTMLButtonElement>;
}

export default function AppBar({
  drawerWidth,
  toolbarVariant,
  isSetupCompleted,
  manager,
  company,
  onOpenDrawerClick,
}: AppBarProps) {
  return (
    <MuiAppBar
      sx={{
        width: {
          md: `calc(100% - ${drawerWidth}px)`,
        },
        ml: {
          md: `${drawerWidth}px`,
        },
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
        borderBottomWidth: 1,
        borderStyle: 'solid',
        borderColor: (theme) => theme.palette.divider,
        backgroundColor: (theme) => theme.palette.background.paper,
      }}
      position="fixed"
      elevation={0}>
      <Toolbar variant={toolbarVariant}>
        <TopNavigation
          isSetupCompleted={isSetupCompleted}
          manager={manager}
          company={company}
          onOpenDrawerClick={onOpenDrawerClick}
        />
      </Toolbar>
    </MuiAppBar>
  );
}
