import { useSuspenseQuery } from '@apollo/client';
import { campaignUsageDocument } from '@social-garden/api/documents/company.ts';

export default function useCampaignUsage() {
  const {
    data: { myCompany },
  } = useSuspenseQuery(campaignUsageDocument);

  if (
    !myCompany.currentSubscription ||
    !myCompany.currentSubscription.subscriptionPlan
  ) {
    return {
      current: myCompany.currentActiveCampaignCount,
      max: 0,
      exceeded: true,
    };
  }

  if (
    myCompany.currentSubscription.subscriptionPlan.maxCampaignCount ===
      undefined ||
    myCompany.currentSubscription.subscriptionPlan.maxCampaignCount === null
  ) {
    return {
      current: myCompany.currentActiveCampaignCount,
      max: null,
      exceeded: false,
    };
  }

  return {
    current: myCompany.currentActiveCampaignCount,
    max: myCompany.currentSubscription.subscriptionPlan.maxCampaignCount,
    exceeded:
      myCompany.currentActiveCampaignCount >=
      myCompany.currentSubscription.subscriptionPlan.maxCampaignCount,
  };
}
