import { InMemoryCache } from '@apollo/client';
import { LocalStorageWrapper, CachePersistor } from 'apollo3-cache-persist';
import { pageFirstPagination } from '@social-garden/utils/pagination.ts';

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        messages: pageFirstPagination(['creatorHasCampaignId']),
        myCampaigns: pageFirstPagination(['input', ['brandIds', 'status']]),
        myCampaignReports: pageFirstPagination(['input', ['brandIds']]),
        creatorsOfCampaign: pageFirstPagination([
          'input',
          ['campaignId', 'status'],
        ]),
      },
    },
    Download: {
      keyFields: ['hash'],
    },
  },
});

const APOLLO_CACHE_PERSIST_KEY = 'apolloCachePersist';

export const persistor = new CachePersistor({
  cache,
  storage: new LocalStorageWrapper(window.localStorage),
  key: APOLLO_CACHE_PERSIST_KEY,
  debug: import.meta.env.DEV,
});
