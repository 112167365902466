import { useOutletContext } from 'react-router';
import {
  MyCompanyQuery,
  MyBrandsQuery,
} from '@social-garden/api/gql/graphql.ts';

export interface ManagerLayoutOutletContext {
  company: MyCompanyQuery['myCompany'];
  brands: MyBrandsQuery['myBrands'];
}

export default function useManagerLayoutOutletContext() {
  return useOutletContext<ManagerLayoutOutletContext>();
}
