import {
  IconButton,
  Stack,
  Toolbar,
  Drawer as MuiDrawer,
  List,
  ListItem,
  ListItemButton,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark';
import { MouseEventHandler } from 'react';
import { NavLink } from 'react-router';
import SideNavigation from './SideNavigation.tsx';
import LogoName from './LogoName.tsx';

interface DrawerProps {
  drawerWidth: number;
  toolbarVariant: 'regular' | 'dense';
  mobileOpen: boolean;
  onCloseDrawerClick: MouseEventHandler<HTMLButtonElement>;
}

export default function Drawer({
  drawerWidth,
  toolbarVariant,
  mobileOpen,
  onCloseDrawerClick,
}: DrawerProps) {
  return (
    <Stack
      sx={{
        width: {
          md: drawerWidth,
        },
      }}
      component="nav">
      <MuiDrawer
        sx={{
          display: {
            md: 'none',
          },
        }}
        PaperProps={{
          sx: {
            width: drawerWidth,
            border: 0,
          },
        }}
        variant="temporary"
        elevation={16}
        open={mobileOpen}
        onClose={onCloseDrawerClick}>
        <Toolbar
          sx={{ alignItems: 'center', justifyContent: 'flex-end' }}
          variant={toolbarVariant}>
          <IconButton color="inherit" onClick={onCloseDrawerClick}>
            <FontAwesomeIcon icon={faXmark} />
          </IconButton>
        </Toolbar>
        <SideNavigation onListItemClick={onCloseDrawerClick} />
      </MuiDrawer>
      <MuiDrawer
        sx={{
          display: {
            xs: 'none',
            md: 'flex',
          },
        }}
        PaperProps={{
          sx: {
            width: drawerWidth,
            border: 0,
            borderRight: 1,
            borderColor: (theme) => theme.palette.divider,
            boxShadow: 'none',
          },
        }}
        variant="permanent"
        elevation={0}
        open>
        <Toolbar variant={toolbarVariant} disableGutters>
          <List sx={{ flex: 1 }} disablePadding>
            <ListItem disablePadding>
              <ListItemButton
                sx={{ justifyContent: 'center' }}
                component={NavLink}
                to="/">
                <LogoName height={32} />
              </ListItemButton>
            </ListItem>
          </List>
        </Toolbar>
        <SideNavigation />
      </MuiDrawer>
    </Stack>
  );
}
