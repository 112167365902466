import { FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormFieldProps } from '../utils/types.ts';
import ImageCropField from './ImageCropField.tsx';

export default function HeaderImageField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  field: { name, onChange },
  fieldState: { error, invalid },
  formState: { isSubmitting },
}: FormFieldProps<TFieldValues, TName>) {
  const { t } = useTranslation(['manager']);
  return (
    <ImageCropField
      required
      label={t('manager:form.field.headerImage.label')}
      name={name}
      width={1080}
      height={463}
      disabled={isSubmitting}
      error={invalid}
      helperText={error?.message}
      onChange={onChange}
    />
  );
}
