import { Checkbox, FormControlLabel, Link } from '@mui/material';
import { FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormFieldProps } from '../utils/types.ts';

export default function TermsOfServiceField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  field: { name, value, onChange, onBlur },
  formState: { isSubmitting },
}: FormFieldProps<TFieldValues, TName>) {
  const { t } = useTranslation(['manager']);
  return (
    <FormControlLabel
      label={
        <Link href={import.meta.env.VITE_TERMS_OF_SERVICE_URL} target="_blank">
          {t('form.field.termsOfService.label')}
        </Link>
      }
      control={
        <Checkbox
          required
          color="primary"
          name={name}
          checked={Boolean(value)}
          onChange={onChange}
          onBlur={onBlur}
        />
      }
      disabled={isSubmitting}
    />
  );
}
