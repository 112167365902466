import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { formatISO } from 'date-fns';
import { useLocation, useNavigate, Location } from 'react-router';
import {
  CampaignGiftType,
  ChannelType,
  TaskItemType,
} from '@social-garden/utils/types';
import { useTranslation } from 'react-i18next';
import {
  Controller,
  FieldError,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Button,
  ButtonGroup,
  Chip,
  Container,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus';
import { AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete/Autocomplete';
import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark';
import {
  assertUnreachable,
  onChangeHelper,
} from '@social-garden/utils/helpers.ts';
import {
  myCampaignsDocument,
  createCampaignDocument,
} from '@social-garden/api/documents/campaign.ts';
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons/faChevronUp';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import { CampaignRewardType } from '@social-garden/api/gql/graphql.ts';
import {
  CreateCampaignFieldValues,
  CreateCampaignSchema,
} from '../../constants/ValidationSchema.ts';
import HeaderImageField from '../../components/HeaderImageField.tsx';
import CampaignNameField from '../../components/CampaignNameField.tsx';
import CampaignDescriptionField from '../../components/CampaignDescriptionField.tsx';
import CategoryField from '../../components/CategoryField.tsx';
import AddressField from '../../components/AddressField.tsx';
import GiftNameField from '../../components/GiftNameField.tsx';
import GiftDescriptionField from '../../components/GiftDescriptionField.tsx';
import RewardTypeField from '../../components/RewardTypeField.tsx';
import FixedMoneyRewardField from '../../components/FixedMoneyRewardField.tsx';
import ApplyUntilField from '../../components/ApplyUntilField.tsx';
import { FormFieldProps } from '../../utils/types.ts';
import Section from '../../components/Section.tsx';
import usePublishCampaign from '../../hooks/usePublishCampaign.ts';
import useCampaignUsage from '../../hooks/useCampaignUsage.ts';
import ChannelTypeField from '../../components/ChannelTypeField.tsx';
import PageTitle from '../../components/PageTitle.tsx';
import Confirm from '../../components/Confirm.tsx';
import GiftTypeField from '../../components/GiftTypeField.tsx';
import BrandField from '../../components/BrandField.tsx';
import InstagramContentTypeField from '../../components/InstagramContentTypeField.tsx';
import YoutubeContentTypeField from '../../components/YoutubeContentTypeField.tsx';
import TiktokContentTypeField from '../../components/TiktokContentTypeField.tsx';
import IncludeContentFileOnSubmissionField from '../../components/IncludeContentFileOnSubmissionField.tsx';
import useStorageSpaceUsage from '../../hooks/useStorageSpaceUsage.ts';
import usePreventLeavingUnsavedForm from '../../hooks/usePreventLeavingUnsavedForm.ts';

export interface CreateCampaignState {
  values: Omit<CreateCampaignFieldValues, 'headerImage'>;
}

export default function CreateCampaign() {
  const { t } = useTranslation(['common', 'manager']);
  const { state }: Location<CreateCampaignState | null | undefined> =
    useLocation();
  const navigate = useNavigate();
  const { exceeded: campaignUsageExceeded } = useCampaignUsage();
  const { exceeded: storageSpaceUsageExceeded } = useStorageSpaceUsage();

  const [createCampaign, { loading: createCampaignLoading }] = useMutation(
    createCampaignDocument,
    {
      refetchQueries: [myCampaignsDocument],
    },
  );
  const [publishCampaign, { loading: publishCampaignLoading }] =
    usePublishCampaign();

  const {
    watch,
    control,
    formState: { isValid, dirtyFields, isSubmitting: isFormSubmitting },
    handleSubmit,
  } = useForm<CreateCampaignFieldValues>({
    resolver: zodResolver(CreateCampaignSchema),
    mode: 'all',
    defaultValues: {
      taskItems: [
        {
          type: TaskItemType.FREE_TEXT,
        },
      ],
      ...(state?.values || {}),
    },
  });

  usePreventLeavingUnsavedForm({ dirtyFields, isSubmitting: isFormSubmitting });

  const {
    fields: taskItemFields,
    append: appendTaskItem,
    remove: removeTaskItem,
    update: updateTaskItem,
    move: moveTaskItem,
  } = useFieldArray<CreateCampaignFieldValues, 'taskItems'>({
    control,
    name: 'taskItems',
  });

  const {
    fields: giftFields,
    append: appendGift,
    remove: removeGift,
  } = useFieldArray<CreateCampaignFieldValues, 'gifts'>({
    control,
    name: 'gifts',
  });

  const {
    fields: questionFields,
    append: appendQuestion,
    remove: removeQuestion,
    move: moveQuestion,
  } = useFieldArray<CreateCampaignFieldValues, 'questions'>({
    control,
    name: 'questions',
  });

  const handleOnCreateCampaign = useCallback(
    async ({
      brandId,
      name,
      description,
      headerImage,
      categoryId,
      type,
      taskItems,
      address,
      gifts,
      questions,
      reward,
      applyUntil,
      includeContentFileOnSubmission,
    }: CreateCampaignFieldValues) =>
      createCampaign({
        variables: {
          input: {
            brandId,
            name,
            description,
            taskItems,
            address,
            gifts,
            headerImage,
            categoryId,
            questions,
            type: {
              channelType: type.channelType,
              instagramCampaign:
                type.channelType === ChannelType.INSTAGRAM
                  ? type.instagramCampaign
                  : undefined,
              youtubeCampaign:
                type.channelType === ChannelType.YOUTUBE
                  ? type.youtubeCampaign
                  : undefined,
              tiktokCampaign:
                type.channelType === ChannelType.TIKTOK
                  ? type.tiktokCampaign
                  : undefined,
            },
            rewardType: reward.rewardType,
            fixedMoneyRewardValue:
              reward.rewardType === CampaignRewardType.FIXED_MONEY_REWARD &&
              reward.fixedMoneyRewardValue
                ? reward.fixedMoneyRewardValue
                : undefined,
            applyUntil: applyUntil
              ? formatISO(applyUntil, { representation: 'date' })
              : undefined,
            includeContentFileOnSubmission,
          },
        },
      }),
    [createCampaign],
  );

  const handleOnCreateCampaignAsDraft = useCallback(
    async (values: CreateCampaignFieldValues) => {
      const result = await handleOnCreateCampaign(values);
      if (result.data?.createCampaign) {
        navigate(`/campaign-details/${result.data.createCampaign.id}`);
      }
    },
    [handleOnCreateCampaign, navigate],
  );

  const handleOnCreateAndPublishCampaign = useCallback(
    async (values: CreateCampaignFieldValues) => {
      const createCampaignResult = await handleOnCreateCampaign(values);
      if (!createCampaignResult.data) {
        return;
      }
      const publishCampaignResult = await publishCampaign(
        createCampaignResult.data.createCampaign.id,
      );
      if (!publishCampaignResult.data) {
        return;
      }
      navigate(
        `/campaign-details/${publishCampaignResult.data.publishCampaign.id}`,
      );
    },
    [handleOnCreateCampaign, navigate, publishCampaign],
  );

  const handleOnTaskItemTypeChange = useCallback(
    (index: number) => (event: SelectChangeEvent<TaskItemType>) => {
      const value = event.target.value as TaskItemType;
      switch (value) {
        case TaskItemType.FREE_TEXT:
          updateTaskItem(index, {
            type: TaskItemType.FREE_TEXT,
            freeTextTask: {
              description: '',
            },
          });
          break;
        case TaskItemType.WEBSITE:
          updateTaskItem(index, {
            type: TaskItemType.WEBSITE,
            websiteTask: {
              url: '',
            },
          });
          break;
        case TaskItemType.HASHTAG:
          updateTaskItem(index, {
            type: TaskItemType.HASHTAG,
            hashtagTask: {
              hashtags: [],
            },
          });
          break;
        default:
          assertUnreachable(value);
      }
    },
    [updateTaskItem],
  );

  const handleOnRemoveTaskItem = useCallback(
    (index: number) => () => removeTaskItem(index),
    [removeTaskItem],
  );

  const handleOnAddTaskItem = useCallback(() => {
    appendTaskItem({
      type: TaskItemType.FREE_TEXT,
      freeTextTask: {
        description: '',
      },
    });
  }, [appendTaskItem]);

  const handleOnAddGift = useCallback(() => {
    appendGift({
      name: '',
      description: '',
      type: CampaignGiftType.CODE,
    });
  }, [appendGift]);

  const handleOnRemoveGift = useCallback(
    (index: number) => () => {
      removeGift(index);
    },
    [removeGift],
  );

  const handleOnAddQuestion = useCallback(() => {
    appendQuestion({
      question: '',
    });
  }, [appendQuestion]);

  const handleOnRemoveQuestionIndex = useCallback(
    (index: number) => () => {
      removeQuestion(index);
    },
    [removeQuestion],
  );

  const renderTaskItemTypeSelect = useCallback(
    (index: number) =>
      // eslint-disable-next-line react/display-name
      ({
        field: { name, value },
        fieldState: { invalid, error },
        formState: { isSubmitting },
      }: FormFieldProps<
        CreateCampaignFieldValues,
        `taskItems.${typeof index}.type`
      >) => (
        <FormControl
          sx={{ width: 250 }}
          disabled={isSubmitting}
          error={invalid}>
          <InputLabel error={invalid}>
            {t('manager:form.field.taskItem.type.label')}
          </InputLabel>
          <Select<TaskItemType>
            label={t('manager:form.field.taskItem.type.label')}
            name={name}
            value={value}
            error={invalid}
            onChange={handleOnTaskItemTypeChange(index)}>
            <MenuItem value={TaskItemType.FREE_TEXT}>
              {t('common:taskItemType.freeText')}
            </MenuItem>
            <MenuItem value={TaskItemType.WEBSITE}>
              {t('common:taskItemType.website')}
            </MenuItem>
            <MenuItem value={TaskItemType.HASHTAG}>
              {t('common:taskItemType.hashtag')}
            </MenuItem>
          </Select>
          <FormHelperText error={invalid}>{error?.message}</FormHelperText>
        </FormControl>
      ),
    [handleOnTaskItemTypeChange, t],
  );

  const renderFreeTextTaskItem = useCallback(
    (index: number) =>
      // eslint-disable-next-line react/display-name
      ({
        field: { name, value, onChange, onBlur },
        fieldState: { invalid, error },
        formState: { isSubmitting },
      }: FormFieldProps<
        CreateCampaignFieldValues,
        `taskItems.${typeof index}.freeTextTask.description`
      >) => (
        <TextField
          fullWidth
          required
          label={t('manager:form.field.freeTextTask.description.label')}
          name={name}
          defaultValue={value}
          disabled={isSubmitting}
          error={invalid}
          helperText={error?.message}
          onChange={onChange}
          onBlur={onBlur}
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    disabled={taskItemFields.length <= 1}
                    onClick={handleOnRemoveTaskItem(index)}>
                    <FontAwesomeIcon icon={faXmark} width={24} height={24} />
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
        />
      ),
    [handleOnRemoveTaskItem, taskItemFields.length, t],
  );

  const renderWebsiteTaskItem = useCallback(
    (index: number) =>
      // eslint-disable-next-line react/display-name
      ({
        field: { name, value, onChange, onBlur },
        fieldState: { invalid, error },
        formState: { isSubmitting },
      }: FormFieldProps<
        CreateCampaignFieldValues,
        `taskItems.${typeof index}.websiteTask.url`
      >) => (
        <TextField
          fullWidth
          required
          label={t('manager:form.field.websiteTask.url.label')}
          name={name}
          defaultValue={value}
          disabled={isSubmitting}
          error={invalid}
          helperText={error?.message}
          onChange={onChange}
          onBlur={onBlur}
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    disabled={taskItemFields.length <= 1}
                    onClick={handleOnRemoveTaskItem(index)}>
                    <FontAwesomeIcon icon={faXmark} width={24} height={24} />
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
        />
      ),
    [handleOnRemoveTaskItem, taskItemFields.length, t],
  );

  const renderHashtagChips = useCallback(
    ({ error }: { error?: FieldError }) =>
      (hashtags: string[], props: AutocompleteRenderGetTagProps) =>
        hashtags.map((hashtag, hashtagIndex) => {
          return (
            <Chip
              {...props({ index: hashtagIndex })}
              key={
                props({ index: hashtagIndex }).key ||
                `${hashtag}_${hashtagIndex}`
              }
              label={hashtag}
              color={
                error && Array.isArray(error) && error[hashtagIndex]
                  ? 'error'
                  : 'primary'
              }
            />
          );
        }),
    [],
  );

  const renderHashtagInput = useCallback(
    ({
      index,
      name,
      invalid,
      error,
    }: {
      index: number;
      name: string;
      invalid: boolean;
      error?: FieldError;
    }) =>
      // eslint-disable-next-line react/display-name
      ({ InputProps, ...rest }: AutocompleteRenderInputParams) => (
        <TextField
          required
          label={t('manager:form.field.hashtags.label')}
          name={name}
          error={invalid}
          helperText={error?.message}
          {...rest}
          slotProps={{
            input: {
              ...InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    disabled={taskItemFields.length <= 1}
                    onClick={handleOnRemoveTaskItem(index)}>
                    <FontAwesomeIcon icon={faXmark} width={24} height={24} />
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
        />
      ),
    [handleOnRemoveTaskItem, taskItemFields.length, t],
  );

  const renderHashtagTaskItem = useCallback(
    (index: number) =>
      // eslint-disable-next-line react/display-name
      ({
        field: { name, value, onChange, onBlur },
        fieldState: { invalid, error },
        formState: { isSubmitting },
      }: FormFieldProps<
        CreateCampaignFieldValues,
        `taskItems.${typeof index}.hashtagTask.hashtags`
      >) => (
        <Autocomplete<string, true, true, true>
          fullWidth
          options={[]}
          value={value}
          freeSolo
          multiple
          disableClearable
          disabled={isSubmitting}
          renderTags={renderHashtagChips({
            error,
          })}
          renderInput={renderHashtagInput({
            index,
            name,
            invalid,
            error,
          })}
          onChange={onChangeHelper(onChange)}
          onBlur={onBlur}
        />
      ),
    [renderHashtagChips, renderHashtagInput],
  );

  const renderQuestionsField = useCallback(
    (index: number) =>
      // eslint-disable-next-line react/display-name
      ({
        field: { name, value, onChange, onBlur },
        fieldState: { error, invalid },
        formState: { isSubmitting },
      }: FormFieldProps<
        CreateCampaignFieldValues,
        `questions.${typeof index}.question`
      >) => (
        <TextField
          fullWidth
          required
          label={`${t('manager:form.field.question.label')} ${index + 1}`}
          name={name}
          defaultValue={value}
          disabled={isSubmitting}
          error={invalid}
          helperText={error?.message}
          onChange={onChange}
          onBlur={onBlur}
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleOnRemoveQuestionIndex(index)}>
                    <FontAwesomeIcon icon={faXmark} width={24} height={24} />
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
        />
      ),
    [handleOnRemoveQuestionIndex, t],
  );

  const moveItemUp = useCallback(
    (index: number, move: (indexA: number, indexB: number) => void) => () => {
      if (index === 0) {
        return;
      }
      move(index, index - 1);
    },
    [],
  );

  const moveItemDown = useCallback(
    (
      index: number,
      maxIndex: number,
      move: (indexA: number, indexB: number) => void,
    ) =>
      () => {
        if (index === maxIndex) {
          return;
        }
        move(index, index + 1);
      },
    [],
  );

  return (
    <Container
      maxWidth="md"
      component="form"
      noValidate
      onSubmit={handleSubmit(handleOnCreateCampaignAsDraft)}>
      <Stack spacing={4}>
        <PageTitle>{t('manager:campaign.create.title')}</PageTitle>
        <Section
          header={t('manager:campaign.form.section.headerImage.header')}
          subHeader={t('manager:campaign.form.section.headerImage.subHeader')}>
          <Controller<CreateCampaignFieldValues, 'headerImage'>
            name="headerImage"
            control={control}
            render={HeaderImageField}
          />
        </Section>
        <Section
          header={t('manager:campaign.form.section.general.header')}
          subHeader={t('manager:campaign.form.section.general.subHeader')}>
          <Controller<CreateCampaignFieldValues, 'name'>
            name="name"
            control={control}
            render={CampaignNameField}
          />
          <Controller<CreateCampaignFieldValues, 'description'>
            name="description"
            control={control}
            render={CampaignDescriptionField}
          />
          <Stack direction="row" spacing={2}>
            <Controller<CreateCampaignFieldValues, 'type.channelType'>
              name="type.channelType"
              control={control}
              render={ChannelTypeField}
            />
            {watch('type.channelType') === ChannelType.INSTAGRAM ? (
              <Controller<
                CreateCampaignFieldValues,
                'type.instagramCampaign.contentType'
              >
                name="type.instagramCampaign.contentType"
                control={control}
                render={InstagramContentTypeField}
              />
            ) : null}
            {watch('type.channelType') === ChannelType.YOUTUBE ? (
              <Controller<
                CreateCampaignFieldValues,
                'type.youtubeCampaign.contentType'
              >
                name="type.youtubeCampaign.contentType"
                control={control}
                render={YoutubeContentTypeField}
              />
            ) : null}
            {watch('type.channelType') === ChannelType.TIKTOK ? (
              <Controller<
                CreateCampaignFieldValues,
                'type.tiktokCampaign.contentType'
              >
                name="type.tiktokCampaign.contentType"
                control={control}
                render={TiktokContentTypeField}
              />
            ) : null}
          </Stack>
          <Controller<
            CreateCampaignFieldValues,
            'includeContentFileOnSubmission'
          >
            name="includeContentFileOnSubmission"
            control={control}
            render={IncludeContentFileOnSubmissionField}
          />
          <Stack direction="row" spacing={2}>
            <Controller<CreateCampaignFieldValues, 'brandId'>
              name="brandId"
              control={control}
              render={BrandField}
            />
            <Controller<CreateCampaignFieldValues, 'categoryId'>
              name="categoryId"
              control={control}
              render={CategoryField}
            />
          </Stack>
        </Section>
        <Section
          header={t('manager:campaign.form.section.tasks.header')}
          subHeader={t('manager:campaign.form.section.tasks.subHeader')}>
          {taskItemFields.map((taskItemField, index) => (
            <Stack key={taskItemField.id} direction="row" spacing={2}>
              <Stack>
                <IconButton
                  size="small"
                  disabled={index === 0}
                  onClick={moveItemUp(index, moveTaskItem)}>
                  <FontAwesomeIcon icon={faChevronUp} />
                </IconButton>
                <IconButton
                  size="small"
                  disabled={index === taskItemFields.length - 1}
                  onClick={moveItemDown(
                    index,
                    taskItemFields.length - 1,
                    moveTaskItem,
                  )}>
                  <FontAwesomeIcon icon={faChevronDown} />
                </IconButton>
              </Stack>
              <Controller<
                CreateCampaignFieldValues,
                `taskItems.${typeof index}.type`
              >
                name={`taskItems.${index}.type`}
                control={control}
                render={renderTaskItemTypeSelect(index)}
              />
              {taskItemField.type === TaskItemType.FREE_TEXT ? (
                <Controller<
                  CreateCampaignFieldValues,
                  `taskItems.${typeof index}.freeTextTask.description`
                >
                  name={`taskItems.${index}.freeTextTask.description`}
                  control={control}
                  render={renderFreeTextTaskItem(index)}
                />
              ) : null}
              {taskItemField.type === TaskItemType.WEBSITE ? (
                <Controller<
                  CreateCampaignFieldValues,
                  `taskItems.${typeof index}.websiteTask.url`
                >
                  name={`taskItems.${index}.websiteTask.url`}
                  control={control}
                  render={renderWebsiteTaskItem(index)}
                />
              ) : null}
              {taskItemField.type === TaskItemType.HASHTAG ? (
                <Controller<
                  CreateCampaignFieldValues,
                  `taskItems.${typeof index}.hashtagTask.hashtags`
                >
                  name={`taskItems.${index}.hashtagTask.hashtags`}
                  control={control}
                  render={renderHashtagTaskItem(index)}
                />
              ) : null}
            </Stack>
          ))}
          <Button
            variant="outlined"
            startIcon={<FontAwesomeIcon icon={faPlus} />}
            disabled={taskItemFields.length >= 10}
            onClick={handleOnAddTaskItem}>
            {t('manager:form.field.task.addButton')}
          </Button>
        </Section>
        <Section
          header={t('manager:campaign.form.section.reward.header')}
          subHeader={t('manager:campaign.form.section.reward.subHeader')}>
          <Stack direction="row" spacing={2}>
            <Controller<CreateCampaignFieldValues, 'reward.rewardType'>
              name="reward.rewardType"
              control={control}
              render={RewardTypeField}
            />
            {watch('reward.rewardType') ===
            CampaignRewardType.FIXED_MONEY_REWARD ? (
              <Controller<
                CreateCampaignFieldValues,
                'reward.fixedMoneyRewardValue'
              >
                name="reward.fixedMoneyRewardValue"
                control={control}
                render={FixedMoneyRewardField}
              />
            ) : null}
          </Stack>
        </Section>
        <Divider>
          <Chip
            variant="outlined"
            label={t('manager:campaign.form.section.optional.header')}
          />
        </Divider>
        <Section
          header={t('manager:campaign.form.section.deadline.header')}
          subHeader={t('manager:campaign.form.section.deadline.subHeader')}>
          <Controller<CreateCampaignFieldValues, 'applyUntil'>
            name="applyUntil"
            control={control}
            render={ApplyUntilField}
          />
        </Section>
        <Section
          header={t('manager:campaign.form.section.location.header')}
          subHeader={t('manager:campaign.form.section.location.subHeader')}>
          <Controller<CreateCampaignFieldValues, 'address'>
            name="address"
            control={control}
            render={AddressField}
          />
        </Section>
        <Section
          header={t('manager:campaign.form.section.gift.header')}
          subHeader={t('manager:campaign.form.section.gift.subHeader')}>
          {giftFields.map((field, index) => (
            <Stack key={field.id} spacing={2}>
              <Controller<
                CreateCampaignFieldValues,
                `gifts.${typeof index}.type`
              >
                name={`gifts.${index}.type`}
                control={control}
                render={GiftTypeField}
              />
              <Controller<
                CreateCampaignFieldValues,
                `gifts.${typeof index}.name`
              >
                name={`gifts.${index}.name`}
                control={control}
                render={GiftNameField}
              />
              <Controller<
                CreateCampaignFieldValues,
                `gifts.${typeof index}.description`
              >
                name={`gifts.${index}.description`}
                control={control}
                render={GiftDescriptionField}
              />
              <Button
                variant="outlined"
                color="inherit"
                startIcon={<FontAwesomeIcon icon={faXmark} />}
                onClick={handleOnRemoveGift(index)}>
                {t('manager:form.field.gift.removeButton')}
              </Button>
              <Divider />
            </Stack>
          ))}
          <Button
            variant="outlined"
            startIcon={<FontAwesomeIcon icon={faPlus} />}
            onClick={handleOnAddGift}>
            {t('manager:form.field.gift.addButton')}
          </Button>
        </Section>
        <Section
          header={t('manager:campaign.form.section.questions.header')}
          subHeader={t('manager:campaign.form.section.questions.subHeader')}>
          {questionFields.map((field, index) => (
            <Stack key={field.id} direction="row" spacing={2}>
              <Stack>
                <IconButton
                  size="small"
                  disabled={index === 0}
                  onClick={moveItemUp(index, moveQuestion)}>
                  <FontAwesomeIcon icon={faChevronUp} />
                </IconButton>
                <IconButton
                  size="small"
                  disabled={index === questionFields.length - 1}
                  onClick={moveItemDown(
                    index,
                    questionFields.length - 1,
                    moveQuestion,
                  )}>
                  <FontAwesomeIcon icon={faChevronDown} />
                </IconButton>
              </Stack>
              <Controller<
                CreateCampaignFieldValues,
                `questions.${typeof index}.question`
              >
                name={`questions.${index}.question`}
                control={control}
                render={renderQuestionsField(index)}
              />
            </Stack>
          ))}
          <Button
            variant="outlined"
            startIcon={<FontAwesomeIcon icon={faPlus} />}
            disabled={questionFields.length >= 5}
            onClick={handleOnAddQuestion}>
            {t('manager:form.field.questions.addButton')}
          </Button>
        </Section>
        <ButtonGroup fullWidth>
          <Button
            size="large"
            type="submit"
            variant="outlined"
            disabled={!isValid || createCampaignLoading}>
            {t('manager:campaign.create.submitButton')}
          </Button>
          <Confirm
            title={t('manager:campaign.confirmPublish.title')}
            description={t('manager:campaign.confirmPublish.description')}>
            <Button
              size="large"
              variant="contained"
              disabled={
                !isValid ||
                campaignUsageExceeded ||
                (watch('includeContentFileOnSubmission') &&
                  storageSpaceUsageExceeded) ||
                createCampaignLoading ||
                publishCampaignLoading
              }
              onClick={handleSubmit(handleOnCreateAndPublishCampaign)}>
              {t('manager:campaign.create.submitAndPublishButton')}
            </Button>
          </Confirm>
        </ButtonGroup>
      </Stack>
    </Container>
  );
}
