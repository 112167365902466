import { useCallback } from 'react';
import { useBeforeUnload, useBlocker } from 'react-router';
import { useTranslation } from 'react-i18next';

export default function usePreventNavigationAndUnload(enabled: boolean) {
  const { t } = useTranslation(['manager']);

  const handleOnBeforeUnload = useCallback(
    (event: BeforeUnloadEvent) => {
      if (enabled) {
        event.preventDefault();
        event.returnValue = '';
        return '';
      }
      return;
    },
    [enabled],
  );

  const handleOnBlockNavigation = useCallback(() => {
    if (enabled) {
      // eslint-disable-next-line no-alert
      const shouldNavigate = confirm(t('manager:navigation.confirmNavigate'));
      return !shouldNavigate;
    }
    return false;
  }, [enabled, t]);

  useBeforeUnload(handleOnBeforeUnload, { capture: true });

  useBlocker(handleOnBlockNavigation);
}
