import { FormControl, FormHelperText, InputLabel } from '@mui/material';
import { FieldPath, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormFieldProps } from '../utils/types.ts';
import InstagramContentTypeSelect from './InstagramContentTypeSelect.tsx';

export default function InstagramContentTypeField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  field: { name, value, onChange },
  fieldState: { error, invalid },
  formState: { isSubmitting },
}: FormFieldProps<TFieldValues, TName>) {
  const { t } = useTranslation(['common']);
  return (
    <FormControl required fullWidth disabled={isSubmitting}>
      <InputLabel id="instagram-content-type-label">
        {t('common:campaign.contentType')}
      </InputLabel>
      <InstagramContentTypeSelect
        variant="outlined"
        labelId="instagram-content-type-label"
        fullWidth
        label={t('common:campaign.contentType')}
        name={name}
        value={value ?? ''}
        error={invalid}
        onChange={onChange}
      />
      <FormHelperText>{error?.message}</FormHelperText>
    </FormControl>
  );
}
