import { graphql } from '../gql';

export const stripeCustomerPortalUrlDocument = graphql(`
  query stripeCustomerPortalUrl($input: StripeCustomerPortalUrlInput!) {
    stripeCustomerPortalUrl(input: $input)
  }
`);

export const registerCompanyDocument = graphql(`
  mutation registerCompany($input: RegisterCompanyInput!) {
    registerCompany(input: $input) {
      id
      name
    }
  }
`);

export const checkoutSubscriptionForCompanyDocument = graphql(`
  mutation checkoutSubscriptionForCompany(
    $input: CheckoutSubscriptionForCompanyInput!
  ) {
    checkoutSubscriptionForCompany(input: $input)
  }
`);

export const brandUsageDocument = graphql(`
  query brandUsage {
    myCompany {
      id
      currentBrandCount
      currentSubscription {
        id
        subscriptionPlan {
          id
          maxBrandCount
        }
      }
    }
  }
`);

export const campaignUsageDocument = graphql(`
  query campaignUsage {
    myCompany {
      id
      currentActiveCampaignCount
      currentSubscription {
        id
        subscriptionPlan {
          id
          maxCampaignCount
        }
      }
    }
  }
`);

export const storageSpaceUsageDocument = graphql(`
  query storageSpaceUsage {
    myCompany {
      id
      usedStorageSpace
      currentSubscription {
        id
        subscriptionPlan {
          id
          storageSpaceLimit
        }
      }
    }
  }
`);

export const myCompanyDocument = graphql(`
  query myCompany {
    myCompany {
      id
      name
      currentActiveCampaignCount
      currentBrandCount
      usedStorageSpace
      currentSubscription {
        id
        status
        subscriptionPlan {
          id
          name
          bookingFeePercentage
          minBookingFee
          maxCampaignCount
          maxBrandCount
          storageSpaceLimit
        }
      }
    }
  }
`);
