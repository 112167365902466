import { getFeedback } from '@sentry/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/pro-solid-svg-icons/faBullhorn';
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuItemProps,
} from '@mui/material';
import { MouseEventHandler, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export default function FeedbackMenuItem({
  sx,
  style,
  selected,
  color,
  onClick,
}: Pick<MenuItemProps, 'sx' | 'style' | 'selected' | 'color' | 'onClick'>) {
  const { t } = useTranslation(['common']);

  const feedback = getFeedback();

  const handleOnClick = useCallback<MouseEventHandler<HTMLLIElement>>(
    async (event) => {
      if (feedback) {
        const form = await feedback.createForm();
        form.appendToDom();
        form.open();
      }
      if (onClick) {
        onClick(event);
      }
    },
    [feedback, onClick],
  );

  if (!feedback) {
    return null;
  }

  return (
    <MenuItem
      sx={sx}
      style={style}
      selected={selected}
      color={color}
      onClick={handleOnClick}>
      <ListItemIcon>
        <FontAwesomeIcon icon={faBullhorn} />
      </ListItemIcon>
      <ListItemText primary={t('common:reportBug')} />
    </MenuItem>
  );
}
