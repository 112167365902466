import { graphql } from '../gql';

export const campaignReportForCampaignDocument = graphql(`
  query campaignReportForCampaign($campaignId: UUID!) {
    campaignReportForCampaign(campaignId: $campaignId) {
      ... on InstagramReelCampaignReport {
        id
        completedCount
        followerCount
        views
        likes
        comments
        shares
        reach
        engagementRate
        cpv
        rewardValue
        creatorHasCampaignReports {
          id
          creatorHasCampaign {
            id
            status
            creator {
              id
              username
            }
          }
          followerCount
          views
          likes
          comments
          shares
          reach
          engagementRate
          cpv
          rewardValue
          lastFetchedAt
        }
      }
      ... on InstagramPostCampaignReport {
        id
        completedCount
        followerCount
        views
        saves
        engagementRate
        cpv
        rewardValue
        creatorHasCampaignReports {
          id
          creatorHasCampaign {
            id
            status
            creator {
              id
              username
            }
          }
          followerCount
          views
          saves
          engagementRate
          cpv
          rewardValue
          lastFetchedAt
        }
      }
      ... on InstagramStoryCampaignReport {
        id
        completedCount
        followerCount
        views
        engagementRate
        cpv
        rewardValue
        creatorHasCampaignReports {
          id
          creatorHasCampaign {
            id
            status
            creator {
              id
              username
            }
          }
          followerCount
          views
          engagementRate
          cpv
          rewardValue
          lastFetchedAt
        }
      }
      ... on TiktokVideoCampaignReport {
        id
        completedCount
        followerCount
        views
        likes
        comments
        shares
        engagementRate
        cpv
        rewardValue
        creatorHasCampaignReports {
          id
          creatorHasCampaign {
            id
            status
            creator {
              id
              username
            }
          }
          followerCount
          views
          likes
          comments
          shares
          engagementRate
          cpv
          rewardValue
          lastFetchedAt
        }
      }
      ... on YoutubeVideoCampaignReport {
        id
        completedCount
        subscriberCount
        views
        likes
        comments
        favorites
        engagementRate
        cpv
        rewardValue
        creatorHasCampaignReports {
          id
          creatorHasCampaign {
            id
            status
            creator {
              id
              username
            }
          }
          subscriberCount
          views
          likes
          comments
          favorites
          engagementRate
          cpv
          rewardValue
          lastFetchedAt
        }
      }
    }
  }
`);
