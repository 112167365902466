import {
  DetailedHTMLProps,
  SyntheticEvent,
  useCallback,
  useRef,
  VideoHTMLAttributes,
} from 'react';
import { useQuery } from '@apollo/client';
import { previewFileDocument } from '@social-garden/api/documents/creatorHasCampaign.ts';
import { useTranslation } from 'react-i18next';
import SuspenseFallback from './SuspenseFallback.tsx';

interface PreviewRevisionVideoProps
  extends Pick<
    DetailedHTMLProps<VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement>,
    'style'
  > {
  previewRevisionId: string;
}

export default function PreviewRevisionVideo({
  style,
  previewRevisionId,
}: PreviewRevisionVideoProps) {
  const { t } = useTranslation(['common']);

  const videoRef = useRef<HTMLVideoElement>(null);

  const { data, loading, error, refetch } = useQuery(previewFileDocument, {
    variables: {
      id: previewRevisionId,
    },
  });

  const handleOnCanPlay = useCallback(async () => {
    if (videoRef.current) {
      await videoRef.current.play();
    }
  }, []);

  const handleOnError = useCallback(
    async (event: SyntheticEvent<HTMLVideoElement, Event>) => {
      if (event.type === 'error') {
        await refetch();
      }
    },
    [refetch],
  );

  if (loading && data === undefined) {
    return <SuspenseFallback />;
  }

  if (error || data === undefined) {
    return null;
  }

  return (
    <video
      key={`${data.previewFile.uri}_${data.previewFile.hash}`}
      ref={videoRef}
      style={{
        display: 'block',
        width: '100%',
        minHeight: 350,
        maxHeight: '80vh',
        objectFit: 'contain',
        ...style,
      }}
      controls
      autoPlay={false}
      preload="none"
      controlsList="nodownload"
      onCanPlay={handleOnCanPlay}
      onError={handleOnError}>
      <source src={data.previewFile.uri} />
      <track kind="captions" />
      {t('common:videoplayer.notSupported')}
    </video>
  );
}
