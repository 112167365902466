import { useCallback } from 'react';
import { DataGrid, GridActionsCellItem, GridRowId } from '@mui/x-data-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/pro-regular-svg-icons/faEye';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router';
import {
  impersonateManagerDocument,
  managersDocument,
} from '@social-garden/api/documents/manager.ts';
import { Tooltip } from '@mui/material';
import usePaginationModel from '../../hooks/usePaginationModel.ts';

export default function AdminManagersTab() {
  const [paginationModel, setPaginationModel] = usePaginationModel();
  const navigate = useNavigate();

  const { data, previousData, loading } = useQuery(managersDocument, {
    variables: {
      first: paginationModel.pageSize,
      page: paginationModel.page + 1,
    },
  });

  const [impersonateManager] = useMutation(impersonateManagerDocument);

  const handleOnImpersonate = useCallback(
    (id: GridRowId) => async () => {
      const result = await impersonateManager({
        variables: {
          id: id.toString(),
        },
      });
      if (result.data?.impersonateManager) {
        navigate(`/impersonate/${result.data.impersonateManager}`);
      }
    },
    [navigate, impersonateManager],
  );

  return (
    <DataGrid
      loading={loading || data == undefined}
      rows={data?.managers.data ?? []}
      columns={[
        {
          width: 300,
          field: 'id',
          headerName: 'ID',
          renderCell: ({ value }) => (
            <Tooltip title={value}>
              <span>{value}</span>
            </Tooltip>
          ),
        },
        {
          flex: 1,
          minWidth: 200,
          field: 'email',
          headerName: 'Email',
          renderCell: ({ value }) => (
            <Tooltip title={value}>
              <span>{value}</span>
            </Tooltip>
          ),
        },
        {
          width: 70,
          field: 'locale',
          headerName: 'Locale',
        },
        {
          field: 'actions',
          type: 'actions',
          getActions: ({ id }) => {
            return [
              <GridActionsCellItem
                key="impersonate"
                icon={<FontAwesomeIcon icon={faEye} />}
                label="Impersonate"
                showInMenu={true}
                onClick={handleOnImpersonate(id)}
              />,
            ];
          },
        },
      ]}
      paginationMode="server"
      rowCount={
        data?.managers.paginatorInfo.total ??
        previousData?.managers.paginatorInfo.total ??
        0
      }
      paginationModel={paginationModel}
      onPaginationModelChange={setPaginationModel}
    />
  );
}
