import { useSuspenseQuery } from '@apollo/client';
import { brandUsageDocument } from '@social-garden/api/documents/company.ts';

export default function useBrandUsage() {
  const {
    data: { myCompany },
  } = useSuspenseQuery(brandUsageDocument);

  if (
    !myCompany.currentSubscription ||
    !myCompany.currentSubscription.subscriptionPlan
  ) {
    return {
      current: myCompany.currentBrandCount,
      max: 0,
      exceeded: true,
    };
  }

  if (
    myCompany.currentSubscription.subscriptionPlan.maxBrandCount ===
      undefined ||
    myCompany.currentSubscription.subscriptionPlan.maxBrandCount === null
  ) {
    return {
      current: myCompany.currentBrandCount,
      max: null,
      exceeded: false,
    };
  }

  return {
    current: myCompany.currentBrandCount,
    max: myCompany.currentSubscription.subscriptionPlan.maxBrandCount,
    exceeded:
      myCompany.currentBrandCount >=
      myCompany.currentSubscription.subscriptionPlan.maxBrandCount,
  };
}
