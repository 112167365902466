import { assertUnreachable } from '@social-garden/utils/helpers.ts';
import { useParams } from 'react-router';
import { useSuspenseQuery } from '@apollo/client';
import { campaignDocument } from '@social-garden/api/documents/campaign.ts';
import { campaignReportForCampaignDocument } from '@social-garden/api/documents/campaignReportable.ts';
import { Container, Stack } from '@mui/material';
import { useMemo } from 'react';
import InstagramReelCampaignReport from './InstagramReelCampaignReport.tsx';
import TiktokVideoCampaignReport from './TiktokVideoCampaignReport.tsx';
import YoutubeVideoCampaignReport from './YoutubeVideoCampaignReport.tsx';
import InstagramPostCampaignReport from './InstagramPostCampaignReport.tsx';
import InstagramStoryCampaignReport from './InstagramStoryCampaignReport.tsx';
import CampaignReportHeader from './CampaignReportHeader.tsx';

export default function CampaignReport() {
  const { id } = useParams();
  const {
    data: { campaign },
  } = useSuspenseQuery(campaignDocument, {
    variables: {
      id: id ?? '',
    },
  });
  const {
    data: { campaignReportForCampaign },
  } = useSuspenseQuery(campaignReportForCampaignDocument, {
    variables: {
      campaignId: id ?? '',
    },
  });

  const reportable = useMemo(() => {
    if (!campaignReportForCampaign) {
      return null;
    }

    switch (campaignReportForCampaign.__typename) {
      case 'InstagramReelCampaignReport':
        return <InstagramReelCampaignReport data={campaignReportForCampaign} />;
      case 'InstagramPostCampaignReport':
        return <InstagramPostCampaignReport data={campaignReportForCampaign} />;
      case 'InstagramStoryCampaignReport':
        return (
          <InstagramStoryCampaignReport data={campaignReportForCampaign} />
        );
      case 'TiktokVideoCampaignReport':
        return <TiktokVideoCampaignReport data={campaignReportForCampaign} />;
      case 'YoutubeVideoCampaignReport':
        return <YoutubeVideoCampaignReport data={campaignReportForCampaign} />;
      default:
        assertUnreachable(campaignReportForCampaign);
    }
  }, [campaignReportForCampaign]);

  return (
    <Container maxWidth="xl" component={Stack} spacing={4}>
      <CampaignReportHeader campaign={campaign} />
      {reportable}
    </Container>
  );
}
