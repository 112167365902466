import { FieldPath, FieldValues } from 'react-hook-form';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormFieldProps } from '../utils/types.ts';

export default function ConfirmPasswordField<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  field: { name, value, onChange, onBlur },
  fieldState: { error, invalid },
  formState: { isSubmitting },
}: FormFieldProps<TFieldValues, TName>) {
  const { t } = useTranslation(['common']);
  return (
    <TextField
      required
      fullWidth
      label={t('common:confirmPassword')}
      autoComplete="current-password"
      type="password"
      name={name}
      defaultValue={value}
      disabled={isSubmitting}
      error={invalid}
      helperText={error?.message}
      onChange={onChange}
      onBlur={onBlur}
    />
  );
}
