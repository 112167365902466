import { useSuspenseQuery } from '@apollo/client';
import { storageSpaceUsageDocument } from '@social-garden/api/documents/company.ts';

export default function useStorageSpaceUsage() {
  const {
    data: { myCompany },
  } = useSuspenseQuery(storageSpaceUsageDocument);

  if (
    !myCompany.currentSubscription ||
    !myCompany.currentSubscription.subscriptionPlan
  ) {
    return {
      current: myCompany.usedStorageSpace,
      max: 0,
      exceeded: true,
    };
  }

  return {
    current: myCompany.usedStorageSpace,
    max: myCompany.currentSubscription.subscriptionPlan.storageSpaceLimit,
    exceeded:
      myCompany.usedStorageSpace >=
      myCompany.currentSubscription.subscriptionPlan.storageSpaceLimit,
  };
}
